import { Layout, Card, Title, IconTag, useTheme } from "@introist/react-foundation/v2";
import { StepStatusBadge } from "modules/journeys/components/StepStatusBadge";
import { IssuesList } from "modules/journeys/containers";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { isEmpty } from "lodash";
import { ExtendedJourneyStepAlertRecord } from "modules/issues/api/AlertApi";
import { Block } from "components/atoms";
import styled from "styled-components";
import { PendingAttributesCard } from "./PendingAttributesCard";
import { AttendeeStatusAvatar } from "../../components/AttendeeStatusAvatar";
import { introistDateTimeToMoment } from "../../../../utils/dates";
import { useMemo } from "react";

interface IJourneyStepStatusBlock {
  step: JourneyStepV2;
  issues?: ExtendedJourneyStepAlertRecord[];
}

export const ReasonCard = styled(Card)`
  padding: 0.375rem;
  padding-right: var(--spacing-small);
`;

export const PreconditionCard = () => {
  const { theme } = useTheme();
  return (
    <ReasonCard>
      <Layout.Group>
        <IconTag
          inverted
          size="small"
          variant="ghosted"
          name="targetBefore"
          color={theme.palette.primary}
          style={{ rotate: "90deg" }}
        />
        <Title variant="bold">Waiting for the preceding steps to complete.</Title>
      </Layout.Group>
    </ReasonCard>
  );
};

export const CancelReasonCard = ({ reason }: { reason?: string | null }) => {
  const reasonText = useMemo(() => {
    if (!reason) return "Step has been canceled";
    if (reason === "REPLACED") return "Step has been replaced";
    return reason;
  }, [reason]);
  return (
    <ReasonCard>
      <Layout.Group>
        <AttendeeStatusAvatar status="declined" />
        <Title variant="bold">{reasonText}</Title>
      </Layout.Group>
    </ReasonCard>
  );
};

export const JourneyStepStatusBlock = ({ step, issues }: IJourneyStepStatusBlock) => {
  const isCanceled = step.status === "canceled";
  const isCompleted = step.status === "completed";
  const hideIssues = isCanceled || isCompleted;

  const hasPendingPreconditions = step.pendingPreconditions.length > 0;
  const hasPendingAttributes = step.pendingPreconditionAttributes.length > 0;

  return (
    <Block blended compact>
      <Layout.Group vertical>
        <Card>
          <Layout.Group vertical>
            <Layout.Group justifyContent="space-between">
              <Title>Status</Title>
              <StepStatusBadge
                status={step.status}
                scheduledAt={introistDateTimeToMoment(step.start).toISOString()}
                stepType={step.stepType}
                pendingPreconditions={step.pendingPreconditions}
              />
            </Layout.Group>
            {isCanceled && <CancelReasonCard reason={step.cancelReason} />}
            {step.remarks && (
              <ReasonCard>
                <Title variant="bold">{step.remarks}</Title>
              </ReasonCard>
            )}
            {issues && !isEmpty(issues) && !hideIssues && <IssuesList issues={issues} />}
            {hasPendingPreconditions && <PreconditionCard />}
          </Layout.Group>
        </Card>
        {hasPendingAttributes && (
          <PendingAttributesCard pendingAttributes={step.pendingPreconditionAttributes} />
        )}
      </Layout.Group>
    </Block>
  );
};
