import { useEffect, useState } from "react";

import { DataForm, Stack, TextButton } from "@introist/react-foundation/v2";
import { Block } from "components/atoms";

import { WorkflowStep } from "services/api/WorkflowApi";
import { JourneyStepV2 } from "services/rpc/RpcProvider";

import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";

import { prefixForm } from "../../../../utils/FormUtils";
import { EmailAddressList } from "./EmailAddressList";
import { isArray } from "lodash";
import { EmailSenderSelector } from "./EmailSenderSelector";

type EmailAddressesBlockProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formKeyPrefix?: string;
  disableCc?: boolean;
  disableBcc?: boolean;
  disableReplyTo?: boolean;
};

const array = (val?: any) => {
  if (isArray(val)) return val;
  return [];
};

export const EmailStepAddressBlock = ({
  form,
  formKeyPrefix,
  disableBcc = false,
  disableCc = false,
  disableReplyTo = false
}: EmailAddressesBlockProps) => {
  const [replyToActive, setReplyToActive] = useState(false);
  const [ccActive, setCcActive] = useState(false);
  const [bccActive, setBccActive] = useState(false);

  const prefixedForm = prefixForm(form, formKeyPrefix);

  useEffect(() => {
    if (prefixedForm.get("replyTo") && prefixedForm.get("replyTo") !== "" && !replyToActive)
      setReplyToActive(true);
    if (array(prefixedForm.get("cc")).length > 0 && !ccActive) setCcActive(true);
    if (array(prefixedForm.get("bcc")).length > 0 && !bccActive) setBccActive(true);
  }, [prefixedForm, replyToActive, ccActive, bccActive]);

  const showAccordionError =
    prefixedForm.isError("emailSenderId") ||
    prefixedForm.isError("replyTo") ||
    prefixedForm.isError("to") ||
    prefixedForm.isError("cc") ||
    prefixedForm.isError("bcc");

  return (
    <Block blended compact>
      <StepEditorAccordion
        title="Sender & Receivers"
        error={showAccordionError ? "Invalid fields" : undefined}
      >
        <Stack vertical alignItems="stretch" gap="large">
          <StepEditorAccordion.Row label="From">
            <EmailSenderSelector
              value={prefixedForm.get("emailSenderId")}
              onSelect={senderId => prefixedForm.set("emailSenderId")(senderId)}
            />
          </StepEditorAccordion.Row>

          {replyToActive && (
            <StepEditorAccordion.Row label="Reply to">
              <EmailAddressList
                value={prefixedForm.get("replyTo") ? [prefixedForm.get("replyTo")] : []}
                onChange={val => {
                  prefixedForm.set("replyTo")(val.length > 0 ? val[0] : null);
                }}
                placeholder="Add reply to"
                single
              />
            </StepEditorAccordion.Row>
          )}

          <StepEditorAccordion.MultiOptionRow label="To">
            <EmailAddressList
              value={isArray(prefixedForm.get("to")) ? prefixedForm.get("to") : []}
              onChange={prefixedForm.set("to")}
              placeholder="Add to"
            />
          </StepEditorAccordion.MultiOptionRow>

          {ccActive && (
            <StepEditorAccordion.MultiOptionRow label="Cc">
              <EmailAddressList
                value={isArray(prefixedForm.get("cc")) ? prefixedForm.get("cc") : []}
                onChange={prefixedForm.set("cc")}
                placeholder="Add cc"
              />
            </StepEditorAccordion.MultiOptionRow>
          )}
          {bccActive && (
            <StepEditorAccordion.MultiOptionRow label="Bcc">
              <EmailAddressList
                value={isArray(prefixedForm.get("bcc")) ? prefixedForm.get("bcc") : []}
                onChange={prefixedForm.set("bcc")}
                placeholder="Add bcc"
              />
            </StepEditorAccordion.MultiOptionRow>
          )}

          <Stack justifyContent="flex-end">
            {!replyToActive && !disableReplyTo && (
              <TextButton onClick={() => setReplyToActive(true)}>Reply to</TextButton>
            )}
            {!ccActive && !disableCc && (
              <TextButton onClick={() => setCcActive(true)}>Cc</TextButton>
            )}
            {!bccActive && !disableBcc && (
              <TextButton onClick={() => setBccActive(true)}>Bcc</TextButton>
            )}
          </Stack>
        </Stack>
      </StepEditorAccordion>
    </Block>
  );
};
