import {
  ActionMenu,
  Card,
  IconButton,
  ModalV2,
  Option,
  SkeletonLoader,
  Stack,
  Tag,
  Title,
  Tooltip,
  useActions
} from "@introist/react-foundation/v2";
import { TriggerPreview as TTriggerPreview, api } from "services/rpc/RpcProvider";
import styled from "styled-components";
import { CollapseList, ListRow } from "components/organisms";
import { useState } from "react";
import { AttributeAvatar, Avatar } from "components/molecules";
import { TriggerPreviewMatchStatusTag } from "./TriggerPreviewMatchStatusTag";
import { ExportData } from "modules/employees/containers/ExportData";

type Props = {
  triggerId: string;
};

const StyledListRow = styled(ListRow)`
  margin-top: -1px;
`;

const LoaderRows = () => (
  <>
    <ListRow gridTemplateColumns="1fr">
      <Stack justifyContent="space-between">
        <Stack>
          <SkeletonLoader width={20} height={20} />
          <SkeletonLoader element="title" />
        </Stack>
        <SkeletonLoader height={22} width={40} />
      </Stack>
    </ListRow>
    <ListRow gridTemplateColumns="1fr">
      <Stack justifyContent="space-between">
        <Stack>
          <SkeletonLoader width={20} height={20} />
          <SkeletonLoader element="title" />
        </Stack>
        <SkeletonLoader height={22} width={40} />
      </Stack>
    </ListRow>
    <ListRow gridTemplateColumns="1fr">
      <Stack justifyContent="space-between">
        <Stack>
          <SkeletonLoader width={20} height={20} />
          <SkeletonLoader element="title" />
        </Stack>
        <SkeletonLoader height={22} width={40} />
      </Stack>
    </ListRow>
  </>
);

export const TriggerPreview = ({ triggerId, ...rest }: Props) => {
  const { onConfirmAction } = useActions();

  const { data: trigger } = api.triggers.find.useQuery({ id: triggerId });
  const preview = api.triggers.preview.useQuery({ id: triggerId });
  const { mutateAsync: skipActivation } = api.triggers.activations.skip.useMutation();

  const [matchOpen, setMatchOpen] = useState(true);
  const [noMatchOpen, setNoMatchOpen] = useState(false);

  const [blameOpen, setBlameOpen] = useState(false);

  const [blamePreview, setBlamePreview] = useState<TTriggerPreview | null>(null);

  const { match, noMatch } = (preview.data || []).reduce(
    (acc, item) => {
      if (item.ruleMatch) {
        acc.match.push(item);
      } else {
        acc.noMatch.push(item);
      }
      return acc;
    },
    { match: [] as TTriggerPreview[], noMatch: [] as TTriggerPreview[] }
  );

  const skipEmployeeActivation = async (employeeId: string) => {
    await skipActivation({ triggerId, employeeId: employeeId });
    await preview.refetch();
  };

  const skipAll = onConfirmAction(
    async () => {
      const pending = (preview.data ?? []).filter(m => m.matchStatus === "pending");

      for (const m of pending) {
        await skipActivation({ triggerId, employeeId: m.employee.id });
      }

      await preview.refetch();
    },
    {
      title: "Skip all pending employees",
      description: "Are you sure you want to skip all pending employees?",
      confirmTitle: "Skip all"
    }
  );

  const getActions = (m: TTriggerPreview) => {
    const actions: Option[] = [
      {
        key: "employee",
        title: "Show employee",
        startAdornmentIcon: "person",
        onClick: () => window.open(`/employees/${m.employee.id}#fields`, "_blank")
      }
    ];

    if (m.matchStatus === "pending") {
      actions.push({
        key: "Skip",
        startAdornmentIcon: "crossCircle",
        onClick: () => skipEmployeeActivation(m.employee.id)
      });
    }

    return actions;
  };

  return (
    <Stack vertical {...rest}>
      <CollapseList
        open={matchOpen}
        emptyState={
          preview.data &&
          match.length === 0 && (
            <StyledListRow gridTemplateColumns="1fr">
              <Title>No matching employees</Title>
            </StyledListRow>
          )
        }
        onToggleOpen={() => setMatchOpen(!matchOpen)}
        header={
          <Stack gap="small" style={{ flex: 1 }}>
            <Title variant="bold">Matching employees</Title>
            {!preview.isLoading && <Title small>{match.length.toString()}</Title>}
            <Stack
              style={{ marginLeft: "auto" }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <ExportData
                data={match.map(m => ({
                  Name: m.employee?.name ?? "Deleted employee",
                  Status: m.matchStatus
                }))}
                tooltip="Export matching employees"
                buttonVariant="blended"
                fileName={`${trigger?.name ?? "matching_employees"}.xlsx`}
                disabled={!match.length}
              />
              <ActionMenu
                options={[
                  {
                    key: "skipAll",
                    title: "Skip all",
                    startAdornmentIcon: "crossCircle",
                    onClick: skipAll
                  }
                ]}
              />
            </Stack>
          </Stack>
        }
      >
        {preview.isLoading && <LoaderRows />}
        {match.map((m, idx) => (
          <StyledListRow key={idx} gridTemplateColumns="auto 1fr auto auto 30px">
            <Avatar nameOrEmail={m.employee.name} />
            <Title variant="bold">{m.employee ? m.employee.name : "Deleted employee"}</Title>

            <TriggerPreviewMatchStatusTag matchStatus={m.matchStatus} />
            <Tooltip tooltip="View include reasons" placement="left">
              <IconButton
                dimmed
                size="medium"
                icon="preview"
                variant="blended"
                onClick={() => {
                  setBlamePreview(m);
                  setBlameOpen(true);
                }}
              />
            </Tooltip>
            <ActionMenu options={getActions(m)} />
          </StyledListRow>
        ))}
      </CollapseList>
      <CollapseList
        open={noMatchOpen}
        onToggleOpen={() => setNoMatchOpen(!noMatchOpen)}
        header={
          <Stack gap="small">
            <Title variant="bold">Excluded employees</Title>
            <Title small>{noMatch.length.toString()}</Title>
          </Stack>
        }
        emptyState={
          preview.data &&
          noMatch.length === 0 && (
            <StyledListRow gridTemplateColumns="1fr">
              <Title>No excluded employees</Title>
            </StyledListRow>
          )
        }
      >
        {preview.isLoading && <LoaderRows />}
        {noMatch.map((m, idx) => (
          <StyledListRow key={idx} gridTemplateColumns="auto 1fr auto auto 30px">
            <Avatar nameOrEmail={m.employee.name} />
            <Title variant="bold">{m.employee ? m.employee.name : "Deleted employee"}</Title>
            <TriggerPreviewMatchStatusTag matchStatus={m.matchStatus} />
            <Tooltip tooltip="View exclude reasons" placement="left">
              <IconButton
                dimmed
                size="medium"
                icon="preview"
                variant="blended"
                onClick={() => {
                  setBlamePreview(m);
                  setBlameOpen(true);
                }}
              />
            </Tooltip>
            <ActionMenu options={getActions(m)} />
          </StyledListRow>
        ))}
      </CollapseList>
      <TriggerBlameModal
        open={blameOpen}
        onClose={() => {
          setBlameOpen(false);
          setTimeout(() => {
            setBlamePreview(null);
          }, 300);
        }}
        preview={blamePreview}
      />
    </Stack>
  );
};

const TriggerBlame = ({ preview }: { preview: TTriggerPreview }) => {
  const employeeFields = api.employees.fields.list.useQuery({});
  const blame = preview.blame;
  const proof = preview.proof;

  return (
    <Stack vertical gap="small">
      {proof.map((b, idx) => {
        const field = employeeFields.data?.find(f => f.variable === b.key);
        const reason = b.reason.replace(b.key, "");
        return (
          <Card key={idx}>
            <Stack vertical gap="xSmall">
              {field && (
                <Stack gap="small">
                  <AttributeAvatar />
                  <Title variant="bold">{field.name}</Title>
                  <Tag colorVariant="success">{b.value ?? "No value"}</Tag>
                </Stack>
              )}
              <Title>{reason}</Title>
            </Stack>
            <Stack></Stack>
          </Card>
        );
      })}

      {blame.map((b, idx) => {
        const field = employeeFields.data?.find(f => f.variable === b.key);
        const reason = b.reason.replace(b.key, "");
        return (
          <Card key={idx}>
            <Stack vertical gap="xSmall">
              {field && (
                <Stack gap="small">
                  <AttributeAvatar />
                  <Title variant="bold">{field.name}</Title>
                  <Tag>{b.value ?? "No value"}</Tag>
                </Stack>
              )}
              <Title>{reason}</Title>
            </Stack>
            <Stack></Stack>
          </Card>
        );
      })}
    </Stack>
  );
};

const TriggerBlameModal = ({
  preview,
  open,
  onClose,
  ...rest
}: {
  open: boolean;
  onClose: VoidFunction;
  preview: TTriggerPreview | null;
}) => {
  return (
    <ModalV2
      {...rest}
      open={open && !!preview}
      onClose={onClose}
      title={`Matching reasons for ${preview?.employee.name}`}
    >
      {preview && <TriggerBlame preview={preview} />}
    </ModalV2>
  );
};
