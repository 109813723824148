import { usePublicFormApi } from "services/api/PublicFormApi";
import { useParams } from "react-router";
import { useState } from "react";
import {
  AlreadySubmitted,
  ErrorForm,
  FormSubmitted,
  LoadingForm
} from "modules/forms/components/PublicForm/StateViews";
import { PublicForm } from "modules/forms/components/PublicForm/PublicForm";

export const PublicFormRoute = () => {
  const { id } = useParams();
  const api = usePublicFormApi();
  const { data: form, isLoading, isError, error } = api.find({ responseId: id! }).query;
  const [isSubmitted, setIsSubmitted] = useState(false);

  if (isLoading) {
    return <LoadingForm />;
  }

  if (form && isSubmitted) {
    return <FormSubmitted />;
  }

  if (isError || !form) {
    const isAlreadySubmitted = (error as any)?.code === 409;

    if (isAlreadySubmitted) {
      return <AlreadySubmitted />;
    }

    return <ErrorForm />;
  }

  return <PublicForm form={form} onSubmitted={() => setIsSubmitted(true)} responseId={id!} />;
};
