import { HtmlEditor } from "components/organisms";
import styled from "styled-components";

const StyledHTMLEditor = styled(HtmlEditor)`
  .ql-container {
    background-color: var(--palette-surface-subdued) !important;
  }
`;

export const ReadOnlyHTMLEditor = ({ html }: { html: string }) => {
  return <StyledHTMLEditor defaultValue={html} readonly={true} />;
};
