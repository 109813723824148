import { useNavigate, useParams } from "react-router";
import { TopBarRoute } from "../../../components/templates";
import { useForm, useFormActions } from "../useForms";
import {
  ActionMenu,
  Button,
  Card,
  Drawer,
  H3,
  Stack,
  Title,
  useActions
} from "@introist/react-foundation/v2";
import { IconPickerIcon } from "../../../components/organisms";
import { useState } from "react";
import { FormForm } from "../FormForm";
import { UpsertModal } from "../../../foundation";
import { TabBar } from "../../../components/molecules";
import { FormFieldList } from "../components/FormFields/FormFieldList";
import { FormFieldEditor } from "../components/FormFields/FormFieldEditor";
import { ShareModal } from "../components/ShareModal";
import { FormResponses } from "../components/FormResponses";
import { DataTransformEditorModal } from "../../../foundation/datatransform/DataTransformEditor";
import { useFormFields } from "../useFormFields";
import { useEmployeeFields } from "../../employees/hooks/useEmployeeFields";
import parseHTML from "html-react-parser";

export const FormRoute = () => {
  const { onConfirmAction } = useActions();
  const navigate = useNavigate();
  const { id, fieldId } = useParams();

  const { form } = useForm(id!);
  const { formFields } = useFormFields(id!);
  const { fields: employeeFields } = useEmployeeFields();

  const { update, archive } = useFormActions();

  const [tab, setTab] = useState(sessionStorage.getItem("intr.forms.tab") ?? "responses");
  const [editOpen, setEditOpen] = useState(false);

  const [shareOpen, setShareOpen] = useState(false);

  const [responseTransformOpen, setResponseTransformOpen] = useState(false);

  const onArchive = onConfirmAction(
    () => archive(form!.id).then(() => navigate("/forms", { replace: true })),
    {
      title: "Confirm archival",
      description: "Are you sure to archive this form?"
    }
  );

  return (
    <TopBarRoute
      narrow
      paths={[
        { key: "forms", title: "Forms", onClick: () => navigate("/forms") },
        { key: "form", title: form?.title || "" }
      ]}
    >
      <Stack vertical>
        {form && (
          <Card style={{ padding: "var(--spacing-xLarge)" }}>
            <Stack vertical gap="small">
              <Stack>
                <Stack vertical gap="xLarge">
                  <Stack>
                    <IconPickerIcon icon="form" fallbackIcon="form" />
                    <H3>{form.title}</H3>
                  </Stack>
                </Stack>
                <Stack style={{ marginLeft: "auto" }}>
                  <Button variant="outlined" startIcon="upload" onClick={() => setShareOpen(true)}>
                    Share
                  </Button>
                  <ActionMenu
                    options={[
                      {
                        key: "edit",
                        title: "Edit",
                        startAdornmentIcon: "pencil",
                        onClick: () => setEditOpen(true)
                      },
                      {
                        key: "edit-transform",
                        title: "Edit Transform",
                        startAdornmentIcon: "arrowsLeftRight",
                        onClick: () => setResponseTransformOpen(true)
                      },
                      {
                        key: "archive",
                        title: "Archive",
                        startAdornmentIcon: "archive",
                        onClick: onArchive
                      }
                    ]}
                  />
                </Stack>
              </Stack>
              {form.description && (
                <Title style={{ marginLeft: "50px" }}>{parseHTML(form.description)}</Title>
              )}
            </Stack>
          </Card>
        )}
        <div style={{ marginRight: "auto" }}>
          <TabBar
            activeTab={tab}
            onTabChange={tab => {
              setTab(tab);
              sessionStorage.setItem("intr.forms.tab", tab);
            }}
            tabs={[
              { id: "responses", title: "Responses", iconName: "comment" },
              { id: "fields", title: "Fields", iconName: "list" }
            ]}
          />
        </div>

        {tab === "fields" && form && (
          <FormFieldList
            formId={form!.id}
            onCreateField={() => navigate(`new`, { relative: "path" })}
            onOpenField={fieldId => navigate(fieldId, { relative: "path" })}
          />
        )}
        {tab === "responses" && form && <FormResponses formId={form!.id} />}
      </Stack>
      <UpsertModal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        entry={form}
        onCreate={() => {
          throw new Error("Not implemented");
        }}
        onUpdate={async entry => {
          await update({ id: form!.id, update: entry });
        }}
        Form={FormForm}
        entryName="Form"
      />
      <Drawer width="500px" open={!!fieldId} onClose={() => navigate(`/forms/${id!}`)}>
        {fieldId && (
          <FormFieldEditor
            formId={id!}
            fieldId={fieldId!}
            onClose={() => navigate(`/forms/${id!}`)}
          />
        )}
      </Drawer>
      <ShareModal formId={id!} open={shareOpen} onClose={() => setShareOpen(false)} />
      {form && (
        <DataTransformEditorModal
          sourceFields={(formFields ?? []).map(f => ({
            key: f.id,
            title: f.shortTitle ?? f.title
          }))}
          targetFields={(employeeFields ?? []).map(f => ({
            key: f.key,
            title: f.title
          }))}
          open={responseTransformOpen}
          onClose={() => setResponseTransformOpen(false)}
          title="Edit Field Transform"
          description="Update Employee's fields on form response"
          onSave={responseTransform => update({ id: form!.id, update: { responseTransform } })}
          initialSpec={form?.responseTransform ?? []}
        />
      )}
    </TopBarRoute>
  );
};
