import { Button, Icon, OptionList, Popover, useTheme } from "@introist/react-foundation/v2";
import { useEmailSenders } from "hooks/use-email-senders";
import { EmployeeField, useEmployeeFields } from "modules/employees/hooks/useEmployeeFields";
import { useCallback, useMemo, useState } from "react";
import { FilledIcon } from "./FilledIcon";
import compact from "lodash.compact";

export const EmailSenderSelector = ({
  value,
  onSelect,
  placeholder = "Select sender"
}: {
  value?: string;
  placeholder?: string;
  onSelect: (key: string | null) => unknown;
}) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const { theme } = useTheme();

  const { senders } = useEmailSenders();
  const { fields } = useEmployeeFields();

  const [buttonTitle, startAdornment] = useMemo(() => {
    if (!value) {
      return [placeholder, <FilledIcon name="person" color={theme.palette.primary} />];
    }
    const sender = senders.find(_ => _.id === value);
    if (sender) {
      return [sender.name, <FilledIcon name="person" color={theme.palette.primary} />];
    }
    const field = fields?.find(_ => _.key === value);
    if (field) {
      return [field.title, <FilledIcon name="attribute" />];
    }

    const [parentFieldKey, childFieldKey] = value.split(".");
    const parentField = fields?.find(_ => _.key === parentFieldKey);
    const childField = fields?.find(_ => _.key === childFieldKey);
    if (parentField && childField) {
      return [`${parentField.title} - ${childField.title}`, <FilledIcon name="person" />];
    }

    return [`Removed field/sender`];
  }, [senders, fields, value, placeholder, theme.palette.primary]);

  return (
    <>
      <Button
        variant="blended"
        size="small"
        ref={setRef}
        onClick={() => setOpen(!open)}
        startAdornment={startAdornment}
      >
        {buttonTitle}
      </Button>
      <EmailSenderPopover open={open} setOpen={setOpen} onSelect={onSelect} popoverRef={ref} />
    </>
  );
};

export const EmailSenderPopover = ({
  open,
  setOpen,
  onSelect,
  popoverRef
}: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  popoverRef: HTMLElement | null;
  onSelect: (fieldPath: string, title: string) => void;
}) => {
  const { theme } = useTheme();
  const [parentField, setParentField] = useState<EmployeeField | null>(null);

  const { senders } = useEmailSenders();
  const { fields } = useEmployeeFields();

  const handleOnSelect = useCallback(
    (key: string, title: string) => {
      onSelect(key, title);
      setOpen(false);
      setParentField(null);
    },
    [onSelect, setOpen, setParentField]
  );

  const options = useMemo(() => {
    const connectedSenderOptions = senders.map(sender => ({
      key: sender.id,
      title: sender.email,
      description: sender.name,
      startAdornment: <FilledIcon name="person" color={theme.palette.primary} />,
      onClick: () => {
        handleOnSelect(sender.id, sender.name);
      }
    }));

    const emailFieldOptions = (fields ?? [])
      .filter(f => f.type === "email")
      .map(f => ({
        key: f.key,
        title: f.title,
        startAdornment: <FilledIcon name="attribute" />,
        onClick: () => {
          const key = parentField ? `${parentField.key}.${f.key}` : f.key;
          const title = parentField ? `${parentField.title} - ${f.title}` : f.title;
          handleOnSelect(key, title);
        }
      }));

    const employeeFields = (fields ?? [])
      .filter(f => f.type === "employee")
      .map(f => ({
        key: f.key,
        title: f.title,
        startAdornment: <FilledIcon name="person" />,
        onClick: () => {
          setParentField(f);
        }
      }));

    if (parentField) {
      return emailFieldOptions;
    }

    return [...connectedSenderOptions, ...emailFieldOptions, ...employeeFields];
  }, [senders, fields, parentField, handleOnSelect, theme.palette.primary]);

  return (
    <Popover
      referenceElement={popoverRef}
      open={open}
      onClose={() => setOpen(false)}
      attachToRef={false}
      closeOnContentClick={false}
    >
      <OptionList
        actions={compact([
          parentField
            ? {
                key: "back",
                title: parentField.title,
                startAdornment: <Icon name="arrowLeft" color={theme.palette.primary.default} />,
                onClick: () => setParentField(null)
              }
            : null
        ])}
        searchable
        options={options}
        clearSearchOnOptionsChange
      />
    </Popover>
  );
};
