import styled from "styled-components";
import { useExpressionEditorContext } from "../context";
import {
  Button,
  Card,
  CloseIconButton,
  H4,
  Icon,
  IconButton,
  Layout,
  ProductTag
} from "@introist/react-foundation/v2";
import { useMemo } from "react";
import { produce } from "immer";
import { FittedTitle } from "components/atoms";
import { hasConditionWithValue } from "../util";
import { ExpressionField } from "../RecursiveExpressionEditor";
import { useExpressionValueInputV2 } from "../hooks";
import { ComparatorSelect } from "./ComparatorSelect";

type RecursiveConditionEditorProps = {
  conditionId: string;
  maxHeight?: number;
  fields: ExpressionField[];
  allowToUseEmployeeFields: boolean;
};

const StyledRecursiveConditionEditor = styled(Layout.Group)`
  overflow: hidden;

  :hover {
    overflow-y: auto;
  }
`;

const StyledModeIndicator = styled.div`
  display: flex;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  position: relative;
  padding-left: var(--spacing-small);
`;

const StyledProductTag = styled(ProductTag)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--palette-foreground-default);
`;

const Line = styled.span`
  display: block;
  border: 1px dashed var(--palette-border-default);
`;

const ModeIndicator = ({ mode }: { mode: "and" | "or" }) => (
  <StyledModeIndicator>
    <Line />
    <StyledProductTag title={mode} />
  </StyledModeIndicator>
);

const AddConditionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--palette-primary-default);
  cursor: pointer;

  :hover {
    color: var(--palette-primary-subdued);
    > svg {
      path {
        fill: var(--palette-primary-subdued);
      }
    }
  }

  > svg {
    path {
      fill: var(--palette-primary-default);
    }
  }
  > h5 {
    color: inherit;
    font-weight: 500;
  }
`;

const DeleteIconButton = styled(IconButton)`
  svg {
    opacity: 0.5;
  }

  :hover svg {
    opacity: 1;
  }
`;

export const RecursiveConditionEditor = ({
  conditionId,
  fields,
  maxHeight,
  allowToUseEmployeeFields,
  ...rest
}: RecursiveConditionEditorProps) => {
  const {
    addCondition,
    getConditionOrGroupById,
    updateConditionById,
    deleteConditionById,
    setSelectedConditionId,
    cancelConditionEdit
  } = useExpressionEditorContext();

  const condition = getConditionOrGroupById(conditionId);

  const { getExpressionValueInput } = useExpressionValueInputV2(() => {}, allowToUseEmployeeFields);

  const field = useMemo(() => {
    if (!condition) return;

    const attributeVariable =
      "attribute" in condition ? condition.attribute : condition.conditions[0].attribute;
    return fields.find(x => x.variable === attributeVariable);
  }, [condition, fields]);

  const comparator = useMemo(() => {
    if (!condition) return;

    const comparator =
      "comparator" in condition ? condition.comparator : condition.conditions[0].comparator;
    return comparator;
  }, [condition]);

  if (!condition) {
    return <H4>Condition not found</H4>;
  }

  if (!field) {
    return <H4>Attribute not found</H4>;
  }

  return (
    <StyledRecursiveConditionEditor {...rest} vertical style={{ maxHeight }}>
      <H4>{field.name}...</H4>

      <Card>
        <Layout.Group vertical gap="large">
          <Layout.Group gap="small">
            <ComparatorSelect
              field={field}
              value={comparator}
              onSelect={comparator => {
                if (!condition.id) {
                  return;
                }

                if ("conditions" in condition) {
                  const nextCondition = produce(condition, draft => {
                    draft.conditions.map(c => (c.comparator = comparator));
                  });
                  updateConditionById(condition.id, nextCondition);
                  return;
                } else {
                  const nextCondition = { ...condition, comparator };
                  updateConditionById(condition.id, nextCondition);
                }
              }}
            />
          </Layout.Group>
          {!!hasConditionWithValue("value" in condition ? [condition] : condition.conditions) && (
            <>
              {"conditions" in condition && (
                <Layout.Group vertical gap="none">
                  {condition.conditions.map((c, index) => {
                    return (
                      <Layout.Group key={`condition-editor-item-${c.id}`} vertical gap="none">
                        <Layout.Group gap="small">
                          {getExpressionValueInput(field, c, updateConditionById)}

                          <CloseIconButton
                            onClick={() => deleteConditionById(c.id, condition.id)}
                          />
                        </Layout.Group>
                        {index !== condition.conditions.length - 1 && <ModeIndicator mode="or" />}
                      </Layout.Group>
                    );
                  })}
                </Layout.Group>
              )}

              {"value" in condition && (
                <Layout.Group gap="small">
                  {getExpressionValueInput(field, condition, updateConditionById)}

                  <CloseIconButton onClick={() => deleteConditionById(condition.id)} />
                </Layout.Group>
              )}
              <Layout.Group>
                <AddConditionButton onClick={() => addCondition(condition.id, condition)}>
                  <Icon name="plusSmall" />
                  <FittedTitle showTooltip maxLength={30}>{`Add ${field.name}`}</FittedTitle>
                </AddConditionButton>
              </Layout.Group>
            </>
          )}
        </Layout.Group>
      </Card>
      <Layout.Group justifyContent="space-between">
        <DeleteIconButton icon="trash" onClick={() => deleteConditionById(condition.id)} />

        <Layout.Group justifyContent="flex-end">
          <Button
            size="small"
            variant="blended"
            onClick={() => {
              cancelConditionEdit(condition.id);
            }}
          >
            Cancel
          </Button>
          <Button size="small" onClick={() => setSelectedConditionId(undefined)}>
            Create
          </Button>
        </Layout.Group>
      </Layout.Group>
    </StyledRecursiveConditionEditor>
  );
};
