import { Button, Icon, IconButton, Tooltip } from "@introist/react-foundation/v2";
import { useCallback } from "react";
import * as xlsx from "xlsx";

export type ExportDataProps = {
  data: any;
  title?: string;
  fileName?: string;
  sheetTitle?: string;
  tooltip?: string;
  buttonVariant?: "outlined" | "blended";
  disabled?: boolean;
};

export const ExportData = ({
  data,
  title,
  fileName,
  sheetTitle,
  tooltip,
  buttonVariant = "outlined",
  disabled = false
}: ExportDataProps) => {
  const exportData = useCallback(async () => {
    const worksheet = xlsx.utils.json_to_sheet(data);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, sheetTitle ?? "Data");

    xlsx.writeFile(workbook, fileName ?? "data.xlsx");
  }, [data, fileName, sheetTitle]);

  return (
    <>
      <Tooltip tooltip={tooltip ?? "Export selected data"}>
        {title ? (
          <Button
            variant={buttonVariant}
            startAdornment={<Icon name="file" />}
            onClickWithLoading={exportData}
            disabled={disabled}
          >
            {title}
          </Button>
        ) : (
          <IconButton
            variant={buttonVariant}
            size="large"
            icon="file"
            onClickWithLoading={exportData}
            disabled={disabled}
          />
        )}
      </Tooltip>
    </>
  );
};
