import { LabelledCheckbox, Stack } from "@introist/react-foundation/v2";
import { ButtonCodeEditor } from "components/templates/CodeEditor/ButtonCodeEditor";

export const PostprocessFunctionEditor = ({
  postprocessFunction,
  onChange
}: {
  postprocessFunction?: string | null;
  onChange: (postprocessFunction: string | null) => unknown;
}) => {
  return (
    <Stack>
      <LabelledCheckbox
        label="Build Automation Data"
        checked={!!postprocessFunction}
        onChange={activated => {
          if (activated) {
            onChange(`function run() {}`);
          } else {
            onChange(null);
          }
        }}
      />
      {!!postprocessFunction && (
        <ButtonCodeEditor value={postprocessFunction} onChange={onChange} />
      )}
    </Stack>
  );
};
