import { Stack, Button, Title } from "@introist/react-foundation/v2";
import { useState, useEffect, useMemo } from "react";

export const MultiActionButton = ({
  selectedItems,
  itemName,
  actionName,
  onAction,
  onCompleted
}: {
  selectedItems: string[];
  itemName: string;
  actionName: string;
  onAction: (item: string) => Promise<unknown>;
  onCompleted: () => unknown;
}) => {
  const [stage, setStage] = useState<"notstarted" | "inprogress" | "completed">("notstarted");

  useEffect(() => {
    if (stage === "completed" && selectedItems.length === 0) return;
    if (stage !== "notstarted" && selectedItems.length > 0) {
      setStage("notstarted");
    }
  }, [selectedItems, stage]);

  const [handledItems, setHandledItems] = useState<string[]>([]);

  const [errorItems, setErrorItems] = useState<string[]>([]);

  const doActions = async () => {
    setHandledItems([]);
    setErrorItems([]);
    setStage("inprogress");
    for (const item of selectedItems) {
      await onAction(item)
        .then(() => {
          setHandledItems([...handledItems, item]);
        })
        .catch(() => {
          setErrorItems([...errorItems, item]);
        });
    }
    setStage("completed");
    onCompleted();
  };

  const title = useMemo(() => {
    if (stage === "notstarted") return `${selectedItems.length} ${itemName}(s) selected`;
    if (stage === "inprogress") return `${handledItems.length}/${selectedItems.length} processed`;
    if (stage === "completed" && errorItems.length === 0) return `Completed!`;
    if (stage === "completed" && errorItems.length > 0)
      return `Completed with ${errorItems.length} errors`;
  }, [selectedItems, handledItems, stage, errorItems.length, itemName]);

  return (
    <Stack>
      <Title>{title}</Title>

      <Button onClickWithLoading={doActions} size="small" disabled={selectedItems.length === 0}>
        {actionName}
      </Button>
    </Stack>
  );
};
