import { UpsertFormProps } from "../../../../foundation";
import { Batch } from "../../useBatches";
import { useWorkflows } from "../../../flows/hooks/useWorkflows";
import { Field, Input, Select, Stack } from "@introist/react-foundation/v2";
import { PostprocessFunctionEditor } from "modules/batches/components/PostprocessFunctionEditor";

export const BatchForm = ({ value, onChange, isEdit }: UpsertFormProps<Batch>) => {
  const { workflows } = useWorkflows({});

  return (
    <Stack vertical style={{ width: "350px" }}>
      <Field title="Title">
        <Input value={value.title} onChange={title => onChange({ ...value, title })} autoFocus />
      </Field>

      <Field title="Workflow">
        <Select
          searchable
          style={{ width: "100%" }}
          options={(workflows ?? []).map(wf => ({ key: wf.id, title: wf.title }))}
          onSelect={opt => onChange({ ...value, workflowId: opt.key })}
          value={value.workflowId}
          disabled={value.stage && value.stage !== "draft"}
        />
      </Field>
      <PostprocessFunctionEditor
        postprocessFunction={value.postprocessFunction}
        onChange={postprocessFunction => onChange({ ...value, postprocessFunction })}
      />
    </Stack>
  );
};
