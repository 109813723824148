import { Modal, useToast, Stack, Title, H4, Select } from "@introist/react-foundation/v2";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getDataSourceSpec } from "../datasources";
import {
  api,
  DataSourcePreviewResult,
  DataSourceTestResult,
  EmployeeDataSource
} from "../../../services/rpc/RpcProvider";
import { FormFooter } from "../components/FormFooter";
import { TabBar } from "components/molecules";
import ReactJson from "@microlink/react-json-view";

type SyncPreviewModalProps = {
  open: boolean;
  onClose: () => void;
  dataSource?: EmployeeDataSource;
};

export const SyncPreviewModal = ({ open, onClose, dataSource }: SyncPreviewModalProps) => {
  const toast = useToast();

  const [preview, setPreview] = useState<DataSourceTestResult | undefined>();

  const doPreview = api.employees.dataSources.preview.useMutation();
  const commit = api.employees.dataSources.commit.useMutation();

  useEffect(() => {
    setPreview(undefined);
  }, [open]);

  const DsPreviewBuilder = useMemo(() => {
    if (!dataSource) return null;
    return getDataSourceSpec(dataSource.sourceType).PreviewBuilder;
  }, [dataSource]);

  const onPreview = useCallback(
    async (data?: any) => {
      await doPreview
        .mutateAsync({ dataSourceId: dataSource!.id, data })
        .then(preview => setPreview(preview))
        .catch(() => {
          toast.error("Failed to generate preview");
        });
    },
    [dataSource, doPreview, toast]
  );

  const onCommit = useCallback(async () => {
    await commit
      .mutateAsync({ dataSourceId: dataSource!.id, data: preview!.matching })
      .then(() => {
        toast.success("Sync completed!");
        onClose();
      })
      .catch(() => {
        toast.error("Failed to sync all employees");
      });
  }, [commit, preview, onClose, toast, dataSource]);

  return (
    <Modal
      title="Preview Data Source sync"
      open={open}
      onClose={onClose}
      style={{ width: "500px" }}
    >
      {!!DsPreviewBuilder && !preview && (
        <DsPreviewBuilder sourceId={dataSource!.id} onPreview={onPreview} onCancel={onClose} />
      )}
      {preview && (
        <>
          <SyncPreview preview={preview} />
          <FormFooter onCancel={onClose} onSubmit={onCommit} submit="Sync" />
        </>
      )}
    </Modal>
  );
};

export const SyncPreview = ({ preview }: { preview: DataSourcePreviewResult }) => {
  const { data: employees } = api.employees.v4.list.useQuery({});
  const [selectedEmployee, setSelectedEmployee] = useState<string>();
  const [tab, setTab] = useState("overview");
  const employeeOptions = useMemo(() => {
    return (
      preview.matching.map(matched => {
        const introistEmployee = employees?.find(_ => _["id"] === matched.introistEmployeeId);
        const employeeName = introistEmployee
          ? `${introistEmployee.firstname} ${introistEmployee.lastname}`
          : "-";
        return {
          key: matched.identifierValue!,
          title: `${employeeName} (${matched.identifierValue})`
        };
      }) ?? []
    );
  }, [employees, preview]);

  const selectedEmployeeData = useMemo(() => {
    if (!selectedEmployee) return undefined;
    return preview.matching.find(_ => _.identifierValue === selectedEmployee);
  }, [selectedEmployee, preview]);

  return (
    <Stack vertical>
      <TabBar
        activeTab={tab}
        onTabChange={tab => setTab(tab)}
        tabs={[
          { id: "overview", title: "Overview" },
          { id: "data", title: "Data" }
        ]}
      />
      {tab === "overview" && (
        <Stack vertical>
          <Stack>
            <H4>{preview.matching.length - preview.matched}</H4>
            <Title>new employees</Title>
          </Stack>
          <Stack>
            <H4>{preview.matched}</H4>
            <Title>employees to update</Title>
          </Stack>
        </Stack>
      )}
      {tab !== "overview" && (
        <Select
          options={employeeOptions}
          onSelect={option => setSelectedEmployee(option.key)}
          value={selectedEmployee}
          sameWidthWithReference
          style={{ width: "100%" }}
        />
      )}
      {tab === "data" && selectedEmployeeData && (
        //@ts-ignore
        <ReactJson
          src={selectedEmployeeData}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
          iconStyle="square"
          name={false}
        />
      )}
      {!selectedEmployee && tab !== "overview" && <Title>Select an employee to view data</Title>}
    </Stack>
  );
};
