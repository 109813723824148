import { Stack, H3, Button, Title } from "@introist/react-foundation/v2";
import { Form, usePublicFormApi } from "services/api/PublicFormApi";
import { useObjectForm } from "../FormFields/useObjectForm";
import { Field } from "./Field";
import { SmoothCard } from "./SmoothCard";
import { GlobalView } from "./ViewFrame";
import parseHtml from "html-react-parser";

export const PublicForm = ({
  form,
  onSubmitted,
  responseId
}: {
  form: Form;
  onSubmitted: () => unknown;
  responseId: string;
}) => {
  const api = usePublicFormApi();

  const doSubmit = api.submit();

  const { value, onChange, hasChanges, errors, submit } = useObjectForm<{ [key: string]: string }>(
    form.values,
    form.values,
    {
      submitErrorMessage: "Submission failed"
    }
  );

  const onSubmit = submit(async values => {
    return doSubmit.mutateAsync({ responseId, submission: values }).then(() => onSubmitted());
  });

  return (
    <GlobalView>
      <Stack>
        <SmoothCard>
          <Stack vertical style={{ width: "100%" }}>
            <H3>{form?.title}</H3>
            {form?.description && <Title>{parseHtml(form.description)}</Title>}
          </Stack>
          <Stack vertical gap="xLarge" style={{ width: "100%" }}>
            {form?.fields.map(field => (
              <Field
                key={field.id}
                field={field}
                value={value[field.id]}
                onChange={onChange(field.id)}
                error={errors[field.id]}
              ></Field>
            ))}
          </Stack>
          <Stack
            justifyContent="center"
            style={{ width: "100%", marginTop: "var(--spacing-xLarge)" }}
          >
            <Button disabled={!hasChanges} onClickWithLoading={onSubmit} startIcon="check">
              Submit
            </Button>
          </Stack>
        </SmoothCard>
      </Stack>
    </GlobalView>
  );
};
