import { Button, Option, Select, Stack, Title, useToast } from "@introist/react-foundation/v2";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import styled from "styled-components";
import { useState } from "react";

type TestResult = {
  value?: any;
  logs?: string[];
};

export type CodeEditorProps = {
  value: string;
  onChange: (value: string) => unknown;
  testDataOptions?: Option[];
  onTest?: (data?: string) => Promise<TestResult>;
};

const SubduedStack = styled(Stack)`
  background-color: var(--palette-surface-subdued);
  padding: var(--spacing-large);
`;

export const CodeEditor = ({ value, onChange, onTest, testDataOptions }: CodeEditorProps) => {
  const toast = useToast();

  const [testData, setTestData] = useState<Option | null>(null);
  const [result, setResult] = useState<TestResult | null>(null);

  return (
    <Stack vertical gap="xxLarge">
      <Stack
        style={{ display: "grid", gridTemplateColumns: "auto 300px", alignItems: "flex-start" }}
        gap="none"
      >
        <CodeMirror
          value={value}
          height="500px"
          extensions={[javascript()]}
          onChange={onChange}
          theme="light"
          style={{
            overflowX: "auto",
            borderRadius: 8
          }}
        />
        <Stack vertical style={{ height: "100%", paddingTop: "4px" }} gap="small">
          <SubduedStack vertical gap="none" style={{ flex: 1, overflowY: "auto" }}>
            {!result && <Title small>Run test to see the logs</Title>}
            {result && (
              <Title small variant="bold" style={{ marginBottom: "12px" }}>
                Run test
                {!!testData ? `: ${testData.title ?? testData.key}` : " without test data"}
              </Title>
            )}
            {result &&
              result.logs &&
              result.logs.map(l => (
                <Title small style={{ whiteSpace: "pre" }}>
                  {l}
                </Title>
              ))}

            {result && (
              <Title variant="bold" small style={{ marginTop: "12px" }}>
                {result.value === undefined && "no value"}
                {result.value === null && "null"}
                {result.value !== undefined && result.value !== null && result.value}
              </Title>
            )}
          </SubduedStack>
          {onTest && testDataOptions && (
            <Select
              searchable
              size="small"
              placeholder="Select test data"
              options={testDataOptions}
              value={testData?.key}
              onSelect={opt => {
                setTestData(opt);
                setResult(null);
              }}
              style={{ width: "100%" }}
            />
          )}
          {!!onTest && (
            <Button
              variant="outlined"
              onClickWithLoading={() =>
                onTest(testData?.key)
                  .then(setResult)
                  .catch(() => toast.error("Test failed"))
              }
            >
              Test
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
