import { Select, Icon } from "@introist/react-foundation/v2";
import { useEffect, useMemo } from "react";
import { useHolidays } from "../hooks/useHolidays";

interface Props {
  country: string;
  setCountry: (country: string) => void;
  clearCountry: () => void;
  year: string;
}

export const CountrySelect = ({ country, setCountry, year, clearCountry }: Props) => {
  const {
    createCustomHoliday,
    areaCodes,
    isLoadingAreaCodes,
    customCalendars,
    isLoadingCustomCalendars
  } = useHolidays(country, year);

  useEffect(() => {
    if (isLoadingCustomCalendars || isLoadingAreaCodes) return;
    const areaCode = areaCodes?.find(a => a.code === country);
    const customCalendar = customCalendars?.find(c => c.areaCode === country);
    if (!areaCode && !customCalendar) {
      clearCountry();
    }
  }, [
    areaCodes,
    customCalendars,
    country,
    clearCountry,
    isLoadingCustomCalendars,
    isLoadingAreaCodes
  ]);

  const countries = useMemo(() => {
    const customCalendarOpts = (customCalendars ?? []).map(c => ({
      key: c.areaCode,
      title: c.title,
      startAdornment: <Icon name="calendarAttribute" />
    }));
    const areaCodeOpts = (areaCodes ?? []).map(c => ({ key: c.code, title: c.name }));
    return [...customCalendarOpts, ...areaCodeOpts];
  }, [areaCodes, customCalendars]);

  const actions = useMemo(() => {
    if (
      !customCalendars ||
      customCalendars.length === 0 ||
      !customCalendars.find(_ => _.areaCode === "GLOBAL")
    ) {
      return [
        { key: `Create Global Calendar for ${year}`, onClick: () => createCustomHoliday(year) }
      ];
    }
    return undefined;
  }, [customCalendars, createCustomHoliday, year]);

  return (
    <Select
      size="small"
      element="button"
      variant="outlined"
      endAdornment={<Icon name="chevronDown" />}
      placeholder="Country"
      searchable
      options={countries}
      value={country}
      onSelect={o => setCountry(o.key)}
      actions={actions}
    />
  );
};
