import { Button, Icon, Indicator, useTheme } from "@introist/react-foundation/v2";
import { useState } from "react";
import { api } from "services/rpc/RpcProvider";
import { WorkflowStepSyncModal } from "./WorkflowStepSyncModal";

interface Props {
  loading?: boolean;
  disabled?: boolean;
  journeysCount?: number;
  stepId: string;
  workflowId: string;
}

export const ActiveEmployeesButton = ({ stepId, workflowId }: Props) => {
  const { theme } = useTheme();
  const [open, setOpen] = useState(false);
  const {
    data: automations,
    isFetching,
    isLoading
  } = api.automations.listWithSteps.useQuery({
    workflowId: workflowId,
    stages: ["active", "draft"],
    workflowStepIds: [stepId]
  });

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        startAdornment={
          automations && automations.length > 0 ? (
            <Indicator color={theme.palette.success} />
          ) : undefined
        }
        onClick={() => {
          setOpen(true);
        }}
        loading={isFetching || isLoading}
        disabled={!automations || automations.length === 0}
        endAdornment={<Icon name="persons" />}
      >
        {automations ? `${automations.length} ` : ""}
      </Button>
      <WorkflowStepSyncModal
        open={open}
        setOpen={setOpen}
        workflowId={workflowId}
        workflowStepId={stepId}
      />
    </>
  );
};
