import { useCallback } from "react";
import { api, Holiday } from "../../../services/rpc/RpcProvider";
import { useActions, useToast } from "@introist/react-foundation/v2";

export const useHolidays = (country: string, year: string) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const { data: areaCodes, isLoading: isLoadingAreaCodes } = api.holidayCalendar.areaCodes.useQuery(
    {}
  );

  const { data: holidays, refetch: refetchHolidays } = api.holidayCalendar.list.useQuery({
    areaCode: country,
    from: `${year}-01-01`,
    to: `${year}-12-31`
  });

  const create = api.holidayCalendar.create.useMutation();
  const update = api.holidayCalendar.update.useMutation();
  const remove = api.holidayCalendar.delete.useMutation();

  const {
    data: customCalendars,
    refetch: refetchCustomCalendars,
    isLoading: isLoadingCustomCalendars
  } = api.holidayCalendar.customCalendar.list.useQuery({
    year
  });

  const deleteCustomCalendar = api.holidayCalendar.customCalendar.delete.useMutation();
  const createCustomCalendar = api.holidayCalendar.customCalendar.create.useMutation();

  const createHoliday = useCallback(
    async (entry: Partial<Holiday>) => {
      const { title, date } = entry;
      await create.mutateAsync({ title: title!, date: date!, areaCode: country }).then(async () => {
        await refetchHolidays();
      });
    },
    [create, refetchHolidays, country]
  );

  const updateHoliday = useCallback(
    async (entry: Partial<Holiday>) => {
      const { title, date } = entry;
      await update
        .mutateAsync({ id: entry.id!, updates: { title: title!, date: date! } })
        .then(async () => {
          await refetchHolidays();
        });
    },
    [update, refetchHolidays]
  );

  const deleteHoliday = onConfirmAction(
    async holidayId => {
      await remove.mutateAsync({ id: holidayId });
      await refetchHolidays();
      toast.success("Holiday deleted");
    },
    {
      icon: "trash",
      title: "Delete holiday",
      description: "Are you sure you want to delete this holiday?",
      confirmTitle: "Delete",
      cancelTitle: "Cancel"
    }
  );

  const deleteCustomHoliday = onConfirmAction(
    async () => {
      const customCalendar = customCalendars?.find(_ => _.areaCode === country && _.year === year);
      if (!customCalendar) {
        toast.warning("Select custom calendar to delete");
        return;
      }
      await deleteCustomCalendar.mutateAsync({ id: customCalendar.id });
      await refetchCustomCalendars();
      toast.success("Calendar deleted");
    },
    {
      icon: "trash",
      title: "Delete calendar",
      description: "Are you sure you want to delete this calendar?",
      confirmTitle: "Delete",
      cancelTitle: "Cancel"
    }
  );

  const createCustomHoliday = onConfirmAction(
    async (year: string) => {
      return createCustomCalendar
        .mutateAsync({ title: "Global", areaCode: "GLOBAL", year })
        .then(async () => {
          await refetchCustomCalendars();
          toast.success("Global calendar created");
        })
        .catch(_ => toast.error("Failed to create global calendar"));
    },
    {
      icon: "calendarAdd",
      title: "Create a Global Holiday Calendar",
      description:
        "This will override all regional and country-specific holiday calendars. Only holidays added to this global calendar will apply to all employees.",
      confirmTitle: "Create Global Calendar",
      cancelTitle: "Cancel"
    }
  );

  return {
    holidays,
    refetchHolidays,
    createHoliday,
    updateHoliday,
    deleteHoliday,
    deleteCustomHoliday,
    createCustomHoliday,
    areaCodes,
    isLoadingAreaCodes,
    customCalendars,
    isLoadingCustomCalendars
  };
};
