import { Button, DataForm, IconButton, Select, Stack } from "@introist/react-foundation/v2";
import { Block } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { prefixForm } from "../../../../../../utils/FormUtils";
import { ReactNode, useMemo, useState } from "react";
import { api } from "../../../../../../services/rpc/RpcProvider";
import { RuleViewer } from "components/organisms/RecursiveExpressionEditor/components/RuleViewer/RuleViewer";
import { useEmployeeFields } from "modules/employees/hooks/useEmployeeFields";
import { ExpressionFieldType } from "components/organisms";
import { expressionToConditionGroup } from "components/organisms/RecursiveExpressionEditor";
import { RuleEditorModal } from "components/organisms/RuleEditorModal/RuleEditorModal";

type EmployeeSelectEditorProps = {
  form: DataForm<any>;
  formPrefix: string;
  readOnly?: boolean;
  extras?: ReactNode;
};
export const EmployeeSelectEditor = ({
  form,
  formPrefix,
  readOnly,
  extras
}: EmployeeSelectEditorProps) => {
  const prefixedForm = prefixForm(form, formPrefix);
  const [ruleEditorOpen, setRuleEditorOpen] = useState(false);

  const { data: employeeGroups } = api.employees.groups.list.useQuery({});

  const { fields } = useEmployeeFields();

  const sourceOptions = useMemo(() => {
    return (employeeGroups ?? []).map(group => ({ key: group.id, title: group.title }));
  }, [employeeGroups]);

  const targetOptions = useMemo(() => {
    const groupAttributes = (fields ?? []).filter(attr =>
      ["group", "employee"].includes(attr.type)
    );
    return groupAttributes.map(attr => ({ key: attr.key, title: attr.title }));
  }, [fields]);

  const conditionGroup = useMemo(() => {
    const condition = prefixedForm.get("condition");
    if (prefixedForm.get("condition") === "") return null;
    return expressionToConditionGroup(condition);
  }, [prefixedForm]);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Assignment" defaultOpen={true} stepOrder={2}>
          <StepEditorAccordion.Row label="Source">
            <Select
              element="button"
              variant="blended"
              placeholder="Select source employee group"
              options={sourceOptions}
              value={prefixedForm.get("sourceGroupId")}
              onSelect={opt => prefixedForm.set("sourceGroupId")(opt.key)}
              attachToRef={false}
              readonly={readOnly}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Target">
            <Select
              element="button"
              variant="blended"
              placeholder="Select attribute group"
              options={targetOptions}
              value={prefixedForm.get("targetGroupAttribute")}
              onSelect={opt => prefixedForm.set("targetGroupAttribute")(opt.key)}
              attachToRef={false}
              readonly={readOnly}
            />
          </StepEditorAccordion.Row>

          {extras}
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Matching rules" defaultOpen={false} stepOrder={3}>
          {conditionGroup && (
            <Stack
              style={{ display: "grid", gridTemplateColumns: "1fr auto", alignItems: "flex-start" }}
            >
              <RuleViewer
                conditionGroup={conditionGroup}
                fields={(fields ?? []).map(f => ({
                  variable: f.key,
                  name: f.title,
                  type: f.type as ExpressionFieldType
                }))}
                collapsedCount={3}
              />
              <Stack vertical>
                <IconButton icon="pencil" onClick={() => setRuleEditorOpen(true)} />
                <IconButton icon="trash" onClick={() => prefixedForm.set("condition")(undefined)} />
              </Stack>
            </Stack>
          )}
          {!conditionGroup && (
            <Stack justifyContent="center" alignItems="center">
              <Button
                size="small"
                variant="blended"
                onClick={() => {
                  setRuleEditorOpen(true);
                }}
              >
                Create
              </Button>
            </Stack>
          )}

          <RuleEditorModal
            expression={prefixedForm.get("condition")}
            open={ruleEditorOpen}
            setOpen={setRuleEditorOpen}
            onSave={prefixedForm.set("condition")}
          />
        </StepEditorAccordion>
      </Block>
    </>
  );
};
