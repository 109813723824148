import { Field, Input, Stack } from "@introist/react-foundation/v2";
import { UpsertFormProps } from "../../foundation";
import { Form } from "./useForms";
import { EditorHandle, HtmlEditor } from "components/editors/HtmlEditor/HtmlEditor";
import { useRef } from "react";

export const FormForm = ({ value, onChange, isEdit }: UpsertFormProps<Form>) => {
  const ref = useRef<EditorHandle | null>(null);
  return (
    <Stack vertical style={{ width: "500px" }}>
      <Field title="Title">
        <Input value={value.title} onChange={title => onChange({ ...value, title })} autoFocus />
      </Field>

      <Field title="Description">
        <HtmlEditor
          ref={ref}
          defaultValue={value.description ?? undefined}
          onChange={description => onChange({ ...value, description })}
          style={{ maxWidth: "500px" }}
          emojis={false}
          gifs={false}
          images={false}
          underline={false}
        />
      </Field>
    </Stack>
  );
};
