import { Button, ModalV2 } from "@introist/react-foundation/v2";
import { useState } from "react";
import { CodeEditor, CodeEditorProps } from "./CodeEditor";

export const ButtonCodeEditor = ({ value, onChange, onTest, testDataOptions }: CodeEditorProps) => {
  const [codeEditorOpen, setCodeEditorOpen] = useState(false);
  return (
    <>
      <Button variant="blended" size="small" onClick={() => setCodeEditorOpen(true)}>
        Open code editor
      </Button>

      <ModalV2 open={codeEditorOpen} onClose={() => setCodeEditorOpen(false)} fullWidth>
        <CodeEditor
          value={value}
          onChange={onChange}
          onTest={onTest}
          testDataOptions={testDataOptions}
        />
      </ModalV2>
    </>
  );
};
