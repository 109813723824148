import { Field, Stack } from "@introist/react-foundation/v2";
import { TextInput } from "components/atoms";
import { StepEditorAccordion } from "../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { ReadOnlyHTMLEditor } from "./ReadOnlyHTMLEditor";

interface ReadOnlyMessageProps {
  subject?: string;
  message: string;
}

export const ReadOnlyMessage = ({ subject, message }: ReadOnlyMessageProps) => {
  return (
    <StepEditorAccordion title="Message">
      <Stack vertical>
        {subject && <TextInput readOnly value={subject} label="Subject" size="small" />}
        {subject && (
          <Field title="Message">
            <ReadOnlyHTMLEditor html={message} />
          </Field>
        )}
        {!subject && <ReadOnlyHTMLEditor html={message} />}
      </Stack>
    </StepEditorAccordion>
  );
};
