import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Icon, Field, InputHandle } from "@introist/react-foundation/v2";

import { TextInput, TextInputProps } from "../../../components/atoms";
import { EmployeeFieldSelectorPopover } from "./EmployeeFieldSelector";

type FieldAwareInputProps = Omit<TextInputProps, "onChange"> & {
  onChange: (value: string) => void;
  addSpaceAfterField?: boolean;
};

const AttributeIcon = styled(Icon).attrs({ name: "attribute" })`
  fill: var(--palette-purple-default);
  cursor: pointer;
`;

export const FieldAwareInput = ({
  onChange,
  value,
  title,
  readOnly,
  placeholder,
  addSpaceAfterField = true,
  ...rest
}: FieldAwareInputProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<InputHandle>(null);
  const [attributesOpen, setAttributesOpen] = useState(false);

  // This function will be triggered when the "Escape" key is pressed
  const escFunction = useRef<(event: KeyboardEvent) => void>(event => {
    if (event.key === "Escape") {
      setAttributesOpen(false);
    }
  });

  const addField = useCallback(
    (path: string) => {
      if (!inputRef.current) return;

      const definedVariable = `{{${path}}}`;
      const newValue = inputRef.current.input?.value.slice(0, -1); // trim the last character

      onChange(newValue || "");

      inputRef.current.insertTextToCursorPosition(definedVariable);
      if (addSpaceAfterField) inputRef.current.insertTextToCursorPosition(" ");
    },
    [inputRef, onChange, addSpaceAfterField]
  );

  useEffect(() => {
    const onKeydown = (event: KeyboardEvent) => {
      if (escFunction.current) escFunction.current(event);
    };

    document.addEventListener("keydown", onKeydown);
    return () => document.removeEventListener("keydown", onKeydown);
  }, []);

  return (
    <>
      <EmployeeFieldSelectorPopover
        setOpen={setAttributesOpen}
        open={attributesOpen}
        popoverRef={ref.current}
        onSelect={addField}
      />

      <Field title={title}>
        <div ref={ref}>
          <TextInput
            {...rest}
            inputHandleRef={inputRef}
            placeholder={placeholder}
            value={value}
            endAdornment={<AttributeIcon onClick={() => setAttributesOpen(!attributesOpen)} />}
            onChange={value => {
              onChange(value);
            }}
            readOnly={readOnly}
            readonly={readOnly}
          />
        </div>
      </Field>
    </>
  );
};
