import { StepEditorProps } from "../useStepEditor";
import { api } from "../../../../services/rpc/RpcProvider";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { ErrorMessage, Icon, Option, Select, Stack } from "@introist/react-foundation/v2";
import { MessageEditor } from "../../../../components/editors/MessageEditor";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { SlackSenderSelectV2 } from "../../../workflows/routes/WorkflowEditor/StepEditor/SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackSenderSelectV2";
import { useEffect, useMemo, useState } from "react";
import { EmployeeFieldCreateModal } from "../../../employees/fields/EmployeeFieldModal";
import { SlackUsersSelect } from "./SlackUsersSelect";
import { SlackChannelSelect } from "./SlackChannelSelect";

export const SlackQuestionMessageEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const { data: fields, refetch: refetchFields } = api.employees.fields.list.useQuery({
    prefixGrouped: true
  });

  const prefixedForm = prefixForm(form, stepDataPath);

  const messageError = prefixedForm.error("message");

  const [createFieldOpen, setCreateFieldOpen] = useState(false);

  const fieldOptions = useMemo(() => {
    const newField: Option = {
      key: "create-new-field",
      title: "Create new field",
      startAdornment: <Icon name="attributeFilled" />
    };

    return [newField, ...(fields ?? []).map(f => ({ key: f.variable, title: f.name }))];
  }, [fields]);

  useEffect(() => {
    const targetType = prefixedForm.get("targetType");
    if (!["channel", "users"].includes(targetType)) {
      prefixedForm.set("targetType")("users");
    }
  }, [prefixedForm]);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Collected Information">
          <StepEditorAccordion.Row label="Field">
            <Select
              element="button"
              variant="blended"
              options={fieldOptions}
              value={prefixedForm.get("field")}
              onSelect={opt => {
                if (opt.key === "create-new-field") {
                  setCreateFieldOpen(true);
                  return;
                } else prefixedForm.set("field")(opt.key);
              }}
              error={prefixedForm.isError("field")}
              startAdornment={<Icon name="attribute" />}
              placeholder="Select a field"
              attachToRef={false}
              searchable
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Sender & Recipients" defaultOpen>
          <StepEditorAccordion.Row label="From">
            <SlackSenderSelectV2
              senderId={prefixedForm.get("senderId")}
              onSelectSender={prefixedForm.set("senderId")}
              error={prefixedForm.isError("senderId")}
            />
          </StepEditorAccordion.Row>
          <Stack vertical gap="small">
            <StepEditorAccordion.Row label="Sending to">
              <Select
                element="button"
                variant="blended"
                options={[
                  { key: "users", title: "Users", startAdornmentIcon: "persons" },
                  { key: "channel", title: "Channel", startAdornmentIcon: "hashtag" }
                ]}
                value={prefixedForm.get("targetType")}
                onSelect={opt => prefixedForm.set("targetType")(opt.key)}
                error={prefixedForm.isError("targetType")}
                placeholder="Select users or channel"
                attachToRef={false}
                searchable
                startAdornment={
                  <Icon name={prefixedForm.get("targetType") === "users" ? "persons" : "hashtag"} />
                }
              />
            </StepEditorAccordion.Row>
            {prefixedForm.get("targetType") === "users" && (
              <StepEditorAccordion.MultiOptionRow label="">
                <SlackUsersSelect
                  senderId={prefixedForm.get("senderId")}
                  error={prefixedForm.isError("users")}
                  value={prefixedForm.get("users") ?? []}
                  onChange={users => {
                    prefixedForm.set("users")(users);
                    prefixedForm.set("channel")(undefined);
                  }}
                />
              </StepEditorAccordion.MultiOptionRow>
            )}
            {prefixedForm.get("targetType") === "channel" && (
              <StepEditorAccordion.Row label="">
                <SlackChannelSelect
                  senderId={prefixedForm.get("senderId")}
                  value={prefixedForm.get("channel")}
                  onChanged={channel => {
                    prefixedForm.set("channel")(channel);
                    prefixedForm.set("users")([]);
                  }}
                  error={prefixedForm.isError("channel")}
                />
              </StepEditorAccordion.Row>
            )}
          </Stack>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Message" defaultOpen>
          <Stack vertical gap="xLarge">
            <MessageEditor
              message={prefixedForm.get("message")}
              onChange={prefixedForm.set("message")}
              error={!!messageError}
            />
            {messageError && <ErrorMessage>{messageError}</ErrorMessage>}
          </Stack>
        </StepEditorAccordion>
      </Block>
      <EmployeeFieldCreateModal
        open={createFieldOpen}
        onClose={() => setCreateFieldOpen(false)}
        onCreated={async field => {
          await refetchFields();
          prefixedForm.set("field")(field.key);
        }}
      />
    </>
  );
};
