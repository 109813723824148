import { Field, Layout } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";

type HarvestConfig = {
  token: string;
  accountId: string;
};
export const HarvestConnectForm = ({ values, onChange }: ConnectFormProps) => {
  const credentials = (values?.credentials as HarvestConfig) ?? {
    token: ""
  };

  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
      <Field title="Api token">
        <TextInput
          placeholder="Api token"
          value={credentials.token}
          type="password"
          onChange={token =>
            onChange({
              credentials: { ...credentials, token }
            })
          }
        />
      </Field>
      <Field title="Account ID">
        <TextInput
          placeholder="Account ID"
          value={credentials.accountId}
          onChange={accountId =>
            onChange({
              credentials: { ...credentials, accountId }
            })
          }
        />
      </Field>
    </Layout.Group>
  );
};
