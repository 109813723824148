import { Checkbox, ModalV2, Stack, Tag, Title, useToast } from "@introist/react-foundation/v2";
import { TabBar, Avatar } from "components/molecules";
import { ListRow } from "components/organisms";
import { ListView } from "foundation";
import { partition } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import { api, AutomationWithSteps } from "services/rpc/RpcProvider";
import { MultiActionButton } from "./MultiActionButton";

export const WorkflowStepSyncModal = ({
  open,
  setOpen,
  workflowId,
  workflowStepId
}: {
  open: boolean;
  setOpen: (open: boolean) => unknown;
  workflowId: string;
  workflowStepId: string;
}) => {
  const toast = useToast();
  const { data: workflowStep } = api.workflows.v4.steps.find.useQuery({ stepId: workflowStepId });

  const { data: automations, refetch } = api.automations.listWithSteps.useQuery({
    workflowId: workflowId,
    stages: ["active", "draft"],
    workflowStepIds: [workflowStepId]
  });

  const [automationsWithStep, automationsWithoutActiveStep] = useMemo(() => {
    return partition(automations, automation =>
      automation.steps.some(
        step => step.workflowStepId === workflowStepId && step.stage !== "ended"
      )
    );
  }, [automations, workflowStepId]);

  const { mutateAsync: addStep } = api.automations.steps.add.useMutation();
  const { mutateAsync: replaceStep } = api.automations.steps.replace.useMutation();
  const { mutateAsync: cancelStep } = api.automations.steps.cancel.useMutation();

  const [tab, setTab] = useState<string>("add");

  const onComplete = async () => {
    await refetch();
    toast.success("Success!");
  };

  const isUpToDate = (automationId: string) => {
    const automation = automations?.find(_ => _.id === automationId);
    if (!automation) return false;
    const step = automation.steps.find(
      _ => _.workflowStepId === workflowStepId && _.stage !== "ended"
    );
    if (!step) return false;
    return step.workflowStepVersion === workflowStep?.version;
  };

  return (
    <>
      <ModalV2 open={open} onClose={() => setOpen(false)}>
        <Stack vertical>
          <TabBar
            activeTab={tab}
            onTabChange={tab => {
              setTab(tab);
            }}
            tabs={[
              {
                id: "add",
                title: `Add (${automationsWithoutActiveStep.length ?? 0})`,
                iconName: "plus"
              },
              {
                id: "replace",
                title: `Replace (${automationsWithStep.length ?? 0})`,
                iconName: "reconnect"
              },
              {
                id: "cancel",
                title: `Cancel (${automationsWithStep.length ?? 0})`,
                iconName: "trash"
              }
            ]}
          />
          {!!automations && tab === "add" && (
            <TabContent
              automations={automationsWithoutActiveStep}
              onAction={async automationId => {
                await addStep({ automationId, workflowStepId });
              }}
              actionName="Add step"
              header="Add step to automations"
              renderDateComponent={_ => {
                const step = _.steps.find(_ => _.workflowStepId === workflowStepId);

                if (!step) {
                  return <Stack justifyContent="flex-end" gap="xSmall"></Stack>;
                } else {
                  return (
                    <Stack justifyContent="flex-end" gap="xSmall">
                      <Title>Completed:</Title>
                      <Title>{moment(step.executedAt).format("DD.MM.YYYY")}</Title>
                    </Stack>
                  );
                }
              }}
              onCompleted={onComplete}
            />
          )}
          {!!automations && tab === "replace" && (
            <TabContent
              automations={automationsWithStep}
              onAction={async automationId => {
                const automation = automations.find(_ => _.id === automationId);
                const automationStep = automation?.steps.find(
                  _ => _.workflowStepId === workflowStepId
                );
                const id = automationStep?.id;
                if (!id) throw new Error("Can't find ID to update automation");
                await replaceStep({ id });
              }}
              actionName="Replace step"
              header="Replace step in automations"
              renderDateComponent={_ => {
                const step = _.steps.find(_ => _.workflowStepId === workflowStepId);
                const stage = step?.stage;

                let title = "";
                let date;

                if (isUpToDate(_.id)) {
                  return (
                    <Stack justifyContent="flex-end" gap="xSmall">
                      <Tag colorVariant="success">Up to date</Tag>
                    </Stack>
                  );
                } else if (stage === "draft") {
                  title = "Draft";
                } else if (stage === "scheduled") {
                  title = "Scheduled:";
                  date = step?.scheduledAt;
                } else {
                  title = "In progress";
                }

                return (
                  <Stack justifyContent="flex-end" gap="xSmall">
                    <Title>{title}</Title>
                    <Title>{date && moment(date).format("DD.MM.YYYY")}</Title>
                  </Stack>
                );
              }}
              onCompleted={onComplete}
              isUpToDate={isUpToDate}
            />
          )}
          {!!automations && tab === "cancel" && (
            <TabContent
              automations={automationsWithStep}
              onAction={async automationId => {
                const automation = automations.find(_ => _.id === automationId);
                const automationStep = automation?.steps.find(
                  _ => _.workflowStepId === workflowStepId
                );
                const id = automationStep?.id;
                if (!id) throw new Error("Can't find ID to cancel automation step");
                await cancelStep({ id });
              }}
              actionName="Cancel step"
              header="Cancel step from automations"
              renderDateComponent={_ => {
                const step = _.steps.find(_ => _.workflowStepId === workflowStepId);
                const stage = step?.stage;

                let title = "";
                let date;

                if (stage === "draft") {
                  title = "Draft";
                } else if (stage === "scheduled") {
                  title = "Scheduled:";
                  date = step?.scheduledAt;
                } else {
                  title = "In progress";
                }

                return (
                  <Stack justifyContent="flex-end" gap="xSmall">
                    <Title>{title}</Title>
                    <Title>{date && moment(date).format("DD.MM.YYYY")}</Title>
                  </Stack>
                );
              }}
              onCompleted={onComplete}
            />
          )}
        </Stack>
      </ModalV2>
    </>
  );
};

const TabContent = ({
  automations,
  onAction,
  actionName,
  header,
  renderDateComponent,
  onCompleted,
  isUpToDate
}: {
  header: string;
  automations: AutomationWithSteps[];
  onAction: (item: string) => Promise<unknown>;
  actionName: string;
  renderDateComponent: (automation: AutomationWithSteps) => JSX.Element;
  onCompleted: () => unknown;
  isUpToDate?: (automationId: string) => boolean;
}) => {
  const [selectedAutomations, setSelectedAutomations] = useState<string[]>([]);
  return (
    <Stack vertical>
      <ListView.Header title={header}></ListView.Header>
      <ListView.List
        data={automations}
        empty={{
          title: "No automations found",
          message: "",
          border: false,
          icon: "crossLarge"
        }}
        renderRow={_ => (
          <ListRow gridTemplateColumns="auto 1fr auto" key={_.id}>
            <Checkbox
              disabled={isUpToDate ? isUpToDate(_.id) : false}
              checked={selectedAutomations.includes(_.id)}
              onChange={() => {
                if (selectedAutomations.includes(_.id)) {
                  setSelectedAutomations(selectedAutomations.filter(selected => selected !== _.id));
                } else {
                  setSelectedAutomations([...selectedAutomations, _.id]);
                }
              }}
            />
            <Stack
              onClick={() => window.open(`/automations/${_.id}`, "_blank")}
              style={{ cursor: "pointer" }}
            >
              <Avatar nameOrEmail={_.employeeName ?? undefined} />
              <Title variant="bold">{_.employeeName}</Title>
            </Stack>
            {renderDateComponent(_)}
          </ListRow>
        )}
      />
      <Stack justifyContent="flex-end">
        <MultiActionButton
          selectedItems={selectedAutomations}
          itemName="automation"
          actionName={actionName}
          onAction={onAction}
          onCompleted={() => {
            setSelectedAutomations([]);
            onCompleted();
          }}
        />
      </Stack>
    </Stack>
  );
};
