import styled from "styled-components";
import { ColorVariants, Icon, IconName, useTheme } from "@introist/react-foundation/v2";

const Background = styled.span<{ baseColor: ColorVariants }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: var(--rounding-small);
  background: ${props => props.baseColor.ghosted};

  svg {
    width: 12px;
    height: 12px;
    fill: ${props => props.baseColor.default} !important;
  }
`;

export const FilledIcon = ({ name, color }: { name: IconName; color?: ColorVariants }) => {
  const { theme } = useTheme();
  return (
    <Background baseColor={color ?? theme.palette.purple}>
      <Icon name={name} />
    </Background>
  );
};
