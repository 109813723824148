import { api } from "../../../services/rpc/RpcProvider";

export const useDataSources = (sourceTypes?: string[]) => {
  const { data: dataSources } = api.employees.dataSources.list.useQuery({});

  return {
    dataSources: sourceTypes
      ? dataSources?.filter(_ => sourceTypes.includes(_.sourceType))
      : dataSources
  };
};
