import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Block } from "../../../../components/atoms";

import { prefixForm } from "../../../../utils/FormUtils";
import { useDataSources } from "../../../datasources/hooks/useDataSources";
import { useEffect, useMemo } from "react";
import { Select } from "@introist/react-foundation/v2";
import { ButtonCodeEditor } from "components/templates/CodeEditor/ButtonCodeEditor";

export const apiMethodOptions = [
  { key: "update-attendance-data", title: "Update attendance data" }
];

export const ApiCallStepEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const { dataSources } = useDataSources(["hibob"]);

  const dataSourceOptions = useMemo(() => {
    if (!dataSources) return [];
    return dataSources.map(s => ({ key: s.id, title: s.name }));
  }, [dataSources]);

  useEffect(() => {
    if (
      !prefixedForm.get("dataProcessFunction") ||
      prefixedForm.get("dataProcessFunction") === ""
    ) {
      prefixedForm.set("dataProcessFunction")("function run({employee}) {}");
    }
    return;
  }, [prefixedForm]);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Target" defaultOpen={!form.data.id}>
          <StepEditorAccordion.Row label="Integration">
            <Select
              size="small"
              element="button"
              variant="blended"
              style={{ width: "100%" }}
              options={dataSourceOptions}
              value={prefixedForm.get("datasourceId")}
              onSelect={opt => prefixedForm.set("datasourceId")(opt.key)}
              placeholder="Select integration"
              attachToRef={false}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="API Method">
            <Select
              size="small"
              element="button"
              variant="blended"
              style={{ width: "100%" }}
              options={apiMethodOptions}
              value={prefixedForm.get("api")}
              onSelect={opt => prefixedForm.set("api")(opt.key)}
              placeholder="Select API method"
              attachToRef={false}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Request processor">
          <StepEditorAccordion.Row label="Function">
            <ButtonCodeEditor
              value={prefixedForm.get("dataProcessFunction")}
              onChange={prefixedForm.set("dataProcessFunction")}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
