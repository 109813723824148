import { ReadOnlyStepProps } from "../useStepEditor";
import { Title } from "@introist/react-foundation/v2";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import ReactJson from "@microlink/react-json-view";
import { apiMethodOptions } from "./ApiCallStepEditor";

export const ApiCallStepViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step) return <div />;

  const { datasourceName, api, processedData } = step.resolvedData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Target" defaultOpen={false}>
          <StepEditorAccordion.Row label="Integration">
            <Title variant="bold">{datasourceName}</Title>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="API Method">
            <Title variant="bold">
              {apiMethodOptions.find(_ => _.key === api)?.title ?? "Undefined"}
            </Title>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Data">
          {/*@ts-ignore */}
          <ReactJson
            src={processedData}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
            iconStyle="square"
            name={false}
          />
        </StepEditorAccordion>
      </Block>
    </>
  );
};
